export function MOAComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/MOA.png`}
                    />
                <div className="text-2xl font-bold">iOS Engineer - 713 LAB </div>
                </div>
                <div className=" text-sm font-semibold">Seoul, South Korea | Jan 2021 - Jan 2022(1 year)</div>
            </div>
            <div className="text-gray-200 my-2 font-semibold mb-5">✨ 아파트 이웃과 이웃을 비대면으로 연결하는아파트 생활지원 컨시어지 플랫폼입니다.   </div>
            <div className='flex flex-row mt-5'>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-pink-300 text-white rounded-md p-1">UIKit</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-red-300 text-white rounded-md p-1">RxSwift</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-orange-300 text-white rounded-md p-1">ReactorKit</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-purple-300 text-white rounded-md p-1">Agora API</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-green-300 text-white rounded-md p-1">Naver Geocoding</div>
            <div className="text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap bg-yellow-300 text-white rounded-md p-1">Open API</div>
            </div>
            <div className="mt-5">
                <div className="my-2 ">• <strong>Reactive Programming(RxSwift, RxCocoa)</strong>를 도입하여 직관적이고 깔끔한 코드와 비동기 작업을 쉽게 처리할 수 있도록 개선하였습니다.             </div>
                <div className="my-2 ">• <strong>ReactorKit</strong> 아키텍쳐를 채택하여 비지니스 로직을 분리하고 단방향성 흐름으로 일관된 코드를 작성할 수 있도록 전반적인 앱을 설계하였습니다.             </div>
                <div className="my-2 ">• <strong>실시간 음성채팅</strong> 기능을 도입하여 코로나 시기에 아파트 주민회의 문제를 비대면으로 해결하도록 기여했습니다.  <strong>동시에 최대 50명 </strong>의 유저가 같은 음성채팅 방에서 회의를 성공적으로 테스트하였습니다.             </div>
                <div className="my-2 ">• <strong>Open API와 Naver Geocoding </strong>을 이용하여 전국 아파트의 각 <strong>주변 1km 이내 아파트를 매칭</strong>하여 <strong>재능 공유, 중고 거래</strong>  기능을 구현하여 실제 가까이 있는 이웃들과 소통할 수 있는 플랫폼을 구축하였습니다.             </div>
                <div className="my-2 ">• <strong>아파트 문화 플랫폼</strong>을 앱스토어에 성공적으로 런칭하고 <strong>은평구청과의 MOU 체결</strong>을 이끌었습니다.             </div>
                <div className="flex flex-row justify-between">
                    <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/MOA/MOA-home.jpeg`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/MOA/MOA-talent.jpeg`}
                    />
                    <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/MOA/MOA-voice.png`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/MOA/MOA-complaint.jpeg`}
                    />
                    <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/MOA/MOA-Chatting.png`}
                    />
                </div>
            </div>
            <img
                className="w-[500px] h-[300px] items-cente mx-auto mt-3"
                src={`${process.env.PUBLIC_URL}/assets/MOA/MOA-contract.jpeg`}
            />
        </div>
    )
}