function LikeyComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Likey.png`}
                    />
                <div className="text-2xl font-bold">iOS Engineer - Likey</div>
                </div>
                <div className=" text-sm font-semibold">Seoul, South Korea  | Jan 2024 - Current</div>
            </div>
            <div className="text-gray-200 my-2 font-semibold mb-5">✨ Like는 크리에이터와 팬을 연결시켜 주는 글로법 소셜 플랫폼입니다..</div>
            <div className='flex flex-row mt-5 text-[10px] md:text-base lg:text-base mr-1 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap'>
            <div className="mr-1 font-semibold bg-orange-300 text-white rounded-md p-1">SwiftUI</div>
            <div className="mr-1 font-semibold bg-red-300 text-white rounded-md p-1">Combine</div>
            <div className="mr-1 font-semibold bg-green-300 text-white rounded-md p-1">UIKit</div>
            <div className="mr-1 font-semibold bg-purple-300 text-white rounded-md p-1">Core Data</div>
            <div className="mr-1 font-semibold bg-blue-300 text-white rounded-md p-1">Agora</div>
            <div className="mr-1 font-semibold bg-pink-300 text-white rounded-md p-1">Sentry</div>
            <div className="mr-1 font-semibold bg-gray-300 text-white rounded-md p-1">Git-Flow</div>
            <div className="mr-1 font-semibold bg-yellow-300 text-white rounded-md p-1">Lokalise</div>
            </div>
            <div className="mt-5 ">
            <div className="my-2 ">• <strong>500만 글로벌</strong> 앱 서비스 Likey 에서 <strong>영상 통화, 라이브 기능을 메인</strong>으로 담당하며 <strong>4명의 iOS 개발자들</strong>과 협업하며 프로젝트를 진행했습니다.  </div>
                <div className="my-2 ">• 영상 통화 및 라이브 <strong>페이스 필터</strong> 기능 추가  </div>
                <div className="my-2 indent-4">◦ 라이브 시청자 수 <strong>5배</strong> 증가 </div>
                <div className="my-2 indent-4">◦ 거래액 <strong>26%</strong> 및 결제 유저 <strong>12%</strong> 증가 </div>
                <div className="my-2 indent-4">◦ DAU 및 결제 유저 <strong>역대 1위</strong> 달성 </div>
                <div className="my-2 ">• 라이브 및 영상 통화 <strong>리팩터링 설계 및 구현</strong> </div>
                <div className="my-2 indent-4">◦ <strong>통합 엔진 프로토콜</strong> 설계하여 라이브, 영상 통화 간 전환 기능 구현 </div>
                <div className="my-2 indent-4">◦ Sentry <strong>중복 영상 통화 로직 크래시</strong> 문제 해결 </div>
                <div className="my-2 indent-4">◦ <strong>플로우 차트 및 문서</strong>를 작성하여 팀원들과 협업 환경 개선 </div>
                <div className="my-2 ">• 8개국 <strong>글로벌 로컬라이제이션</strong> 및 번역 기능 추가하여 동적 UI 지원 </div>
                <div className="my-2 ">• <strong>AI 챗봇</strong> 채팅방 기능 및 커스텀 애니메이션 기능 추가  </div>
            </div>

            <div className="flex flex-row justify-around">
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-ai-chat.gif`}
                />
                <img
                    className="w-1/5 h-1/2 mx-2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-face-filter.gif`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-video-call.gif`}
                />
                <img
                    className="w-1/5 h-1/2 mx-2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-live-creator.gif`}
                />
                <img
                    className="w-1/5 h-1/2"
                    src={`${process.env.PUBLIC_URL}/assets/Likey/Likey-live-fan.gif`}
                    />
            </div>
        </div>
    )
}

export default LikeyComponent;