
import { useEffect, useRef, useState } from 'react';
import CarrotEZComponent from './Components/CarrotEZComponent';
import IntroductionComponent from './Components/IntroductionComponent';
import { MOAComponent } from './Components/MOAComponent';
import { ComGenAmericaComponent } from './Components/ComGenAmericaComponent';
import BrandiaryComponent from './Components/BrandiaryComponent';
import { TimebankComponent } from './Components/TimebankComponent';
import { MagicFightComponent } from './Components/MagicFightComponent';
import { CreativeCodingComponent } from './Components/CreativeCoding/CreativeCodingComponent';
import { EasierPathComponent } from './Components/EasierPathComponent';
import { ThreeDCardViewComponent } from './Components/ThreeDCardViewComponent';
import { FomaViewableSoundComponent } from './Components/FomaViewableSoundComponent';
import { DetailCard } from './Components/CreativeCoding/DetailCard';
import { PortfolioState } from '../redux/PortfolioSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { none } from './Components/CreativeCoding/ProjectInfo';
import { HardWorking } from './Components/HardWorking';
import { Creative } from './Components/Creative';
import { Experienced } from './Components/Experienced';
import { portfolioSlice } from '../redux/PortfolioSlice';
import { useDispatch } from 'react-redux';
import LikeyComponent from './Components/Likey';
import { MeloryComponent } from './Components/Melory';

function PortfolioScreen() {
    const actions = portfolioSlice.actions;
    const dispatch = useDispatch();
    const portfolioState = useSelector<RootState, PortfolioState>(
        (state) => state.portfolio
    );

    const [showDetailCardView, setShowDetailCardView] = useState(false)
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const gridItemRefs = useRef<(HTMLDivElement | null)[]>([]);

    const handleItemClick = (index: number) => {
        if (scrollRef.current && gridItemRefs.current[index]) {
            gridItemRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        setShowDetailCardView(portfolioState.selectedCreativeCoding != none)
      }, [portfolioState.selectedCreativeCoding]);

    return (
        <div className="lg:flex w-screen h-screen text-white text-base" >
            <div className={`${showDetailCardView ? 'visible' : 'invisible'} absolute flex z-20 w-11/12 h-11/12 justify-center items-center z-1`} >
                <DetailCard />
            </div>
            <div className="lg:w-1/3 lg:h-1/3 sm:w-screen sm:h-full">
                <div className="relative flex w-full justify-center">
                    <div className="absolute w-3/5 z-10 h-5/6 flex flex-col justify-between mt-24">
                        <div className="grid grid-cols-4 gap-y-10 gap-x-8 mx-5">
                        <button className="flex flex-col items-center" onClick={() => handleItemClick(15)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-logo.png`}
                                />
                                <div className=" text-white text-center mt-2 text-xs truncate">Melory</div>
                            </button>
                        <button className="flex flex-col items-center" onClick={() => handleItemClick(1)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/Likey.png`}
                                />
                                <div className=" text-white text-center mt-2 text-xs truncate">Likey</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(2)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/CarrotEZ.png`}
                                />
                                <div className=" text-white text-center mt-2 text-xs truncate">CarrotEZ</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(3)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/ComGen.png`}
                                />
                                <div className=" text-white text-center mt-1 text-xs">ComGen America</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(4)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/MOA.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs w-28">모두의 아파트</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(5)}>
                                <img
                                    className="flex w-12 h-124 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/Brandiary.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">Brandiay</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(6)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/Creative-Coding.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">Creative Coding</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(14)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/EasierPath.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">EasierPath</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(7)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/Magic-Fight.webp`}
                                />
                                <div className="text-white text-center mt-1 text-xs w-28">
                                    Magic Fight
                                </div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(8)}>
                                <img
                                    className="flex w-12 h-12 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/Timebank/Timebank-logo.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs w-18">
                                    Time Bank
                                </div>
                            </button>
                        </div>

                        <div className="grid grid-cols-4 gap-y-10 gap-x-8 mb-5 bg-stone-800 px-2 py-2 rounded-3xl m-5">
                            <button className="flex flex-col items-center" onClick={() => handleItemClick(0)}>
                                <img
                                    className="flex w-11 h-11 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/profile.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">Young</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => {
                                handleItemClick(8)
                                window.open('https://github.com/fomagran', "_blank")
                            }
                        }>
                                <img
                                    className="flex w-11 h-11 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/github.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">Github</div>
                            </button>

                            <button className="flex flex-col items-center" onClick={() => {
                                handleItemClick(9)
                                window.open('https://fomaios.tistory.com/', "_blank")
                            }
                        }>
                                <img
                                    className="flex w-11 h-11 rounded-lg bg-black"
                                    src={`${process.env.PUBLIC_URL}/assets/tistory.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">Tistory</div>
                            </button>
                            <button className="flex flex-col items-center" onClick={() => {
                                handleItemClick(10)
                                window.open('https://linkedin.com/in/younghunan/', "_blank")
                            }
                        }>
                                <img
                                    className="flex w-11 h-11 rounded-lg bg-white"
                                    src={`${process.env.PUBLIC_URL}/assets/linkedin.png`}
                                />
                                <div className="text-white text-center mt-1 text-xs">Linkedin</div>
                            </button>
                        </div>
                    </div>
                    <img
                        className="w-11/12"
                        src={`${process.env.PUBLIC_URL}/assets/iphone.png`}
                    />
                </div>
            </div>
            <div className="lg:w-2/3 sm:w-full h-full lg:overflow-y-scroll" ref={scrollRef}>
                <div ref={(el) => (gridItemRefs.current[0] = el)} className="lg:flex w-full py-5">
                    <div className='w-full'>
                        <IntroductionComponent />
                        <div className='mx-10'>
                        <div className='my-5'>저의 <strong className="text-sky-400">장점 3가지는</strong> 아래와 같습니다. (단어를 <strong className="text-green-300">클릭</strong>하시면 스크롤하여 이유를 확인하실 수 있습니다.)</div>
                        <div className='hover:cursor-pointer text-green-300 w-[200px]' onClick={() => handleItemClick(11)}>• Creative 🧩</div>
                        <div className='hover:cursor-pointer my-2 text-red-300 w-[200px]' onClick={() => handleItemClick(12)}> • Hard-Working 💪</div>
                        <div className='hover:cursor-pointer text-blue-300 w-[200px]' onClick={() => handleItemClick(13)}>• Experienced 💼</div>
                        </div>
                    </div>
                </div>
                <div ref={(el) => (gridItemRefs.current[11] = el)} className="py-5">
                    <Creative />
                </div>
                <div ref={(el) => (gridItemRefs.current[6] = el)} className="pb-5">
                    <CreativeCodingComponent />
                </div>
                <div ref={(el) => (gridItemRefs.current[12] = el)} className="py-5">
                    <HardWorking />
                </div>
                <div ref={(el) => (gridItemRefs.current[13] = el)} className="py-5">
                    <Experienced />
                </div>
                <div className='mx-5'>
                    <div className='text-white text-2xl font-bold mx-5 my-5'>WORK EXPERIENCE</div>
                    <div className='flex w-full h-px bg-zinc-500 mx-5'></div>
                    <div className='mx-5 mt-5'>✅ 스타트업 창업 멤버이자 iOS 개발자로서 기획부터 운영까지 다양한 업무 경험을 했습니다.</div>
                    <div ref={(el) => (gridItemRefs.current[1] = el)} className="py-5">
                        <LikeyComponent />
                    </div>
                    <div ref={(el) => (gridItemRefs.current[2] = el)} className="py-5">
                        <CarrotEZComponent />
                    </div>
                    <div ref={(el) => (gridItemRefs.current[3] = el)} className="py-5">
                        <ComGenAmericaComponent />
                    </div>
                    <div ref={(el) => (gridItemRefs.current[4] = el)} className="py-5">
                        <MOAComponent />
                    </div>
                    <div ref={(el) => (gridItemRefs.current[5] = el)} className="py-5">
                        <BrandiaryComponent />
                    </div>
                    <div className='text-white text-2xl font-bold mx-5 my-5'>OPEN SOURCE</div>
                    <div className='flex w-full h-px bg-zinc-500 mx-5 mb-5'></div>
                    <div className='mx-5 my-5'> ✅ Cocoapod을 통해 라이브러리를 직접 개발하여 오픈 소스에 기여한 경험이 있습니다.</div>
                    <div ref={(el) => (gridItemRefs.current[14] = el)} className="py-5">
                    <EasierPathComponent />
                    </div>
                    <div className="py-5">
                        <ThreeDCardViewComponent />
                    </div>
                    <div className="py-5">
                        <FomaViewableSoundComponent />
                    </div>
                    <div className='text-white text-2xl font-bold mx-5 my-5'>PROJECT</div>
                    <div className='flex w-full h-px bg-zinc-500 mx-5'></div>
                    <div className='mx-5 my-5'> ✅ 개인 프로젝트를 앱스토어에 성공적으로 런칭했습니다. </div>
                    <div ref={(el) => (gridItemRefs.current[15] = el)} className="py-5">
                        <MeloryComponent />
                    </div>
                    <div ref={(el) => (gridItemRefs.current[7] = el)} className="py-5">
                        <MagicFightComponent />
                    </div>
                    <div ref={(el) => (gridItemRefs.current[8] = el)} className="py-5">
                        <TimebankComponent />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioScreen;