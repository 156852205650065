export function MeloryComponent() {
    return (
        <div className='mx-10'>
            <div className="flex justify-between">
            <div className="flex flex-row items-center">
                    <img
                        className="flex w-12 h-12 rounded-lg bg-white mr-2"
                        src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-logo.png`}
                    />
                <div className="text-2xl font-bold">Melory</div>
                </div>
                <div className=" text-sm font-semibold">Dec 2024 - Current</div>
            </div>
            <div className="mt-5">
            <button className="my-2" onClick={ () => window.open('https://apps.apple.com/us/app/melory/id6738697057', "_blank") }><strong>https://apps.apple.com/us/app/melory/id6738697057</strong> </button>
                <div className="my-2 ">• <strong>Melory</strong>는  <strong>음악</strong>을 들을 때 소중한 기억들을 기록하고 <strong>음악과 함께 동영상으로 재생</strong>할 수 있는 서비스입니다. </div>
                <div className="my-2 indent-4">◦ <strong>AVCaptureSession</strong>을 활용하여 <strong>커스텀 Recording</strong> 기능 구현하여 <strong>동영상 파일 변환 및 업로드</strong> 기능 구현</div>
                <div className="my-2 indent-4">◦ <strong>MediaPlayer</strong>를 활용하여 현재 유저가 듣고 있는 <strong>음악 정보, 구간, 재생여부를 파악</strong> 및 특정 구간 재생 기능 구현</div>
                <div className="my-2 indent-4">◦ <strong>AVPlayer</strong>를 활용하여 음악 특정 구간 재생 시 <strong>동영상 자동 재생기능</strong> 구현</div>
                <div className="flex flex-row justify-between">
                    <img
                        className="w-1/5 h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-app-1.png`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-app-2.png`}
                    />
                    <img
                        className="w-1/5  h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-app-3.png`}
                    />
                    <img
                        className="w-1/5 h-1/2 mx-2"
                        src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-app-4.png`}
                    />
                    <img
                        className="w-1/5  h-1/2"
                        src={`${process.env.PUBLIC_URL}/assets/Melory/Melory-app-5.png`}
                    />
                </div>
            </div>
        </div>
    )
}